import React from "react";

import { Container, styled } from "@mui/material";
import { PaperPageError, PaperPageSpinner } from "../../components/PaperPage";
import { CreateObject } from "./CreateObject";
import { EventForms } from "./EventForms";

import { useGetListQuery } from "../../slices/lists/api";
import useWebsocket from "../../utils/useWebsocket";
import { WebsocketActionType } from "../../slices/Websocket/types";

import { FormValues } from "./types";
import { QueryListName } from "../../slices/lists/types";

interface EventsProps {
  onClose?: () => void;
  refetchFunc: (() => void) | null;
}

const Events: React.FC<EventsProps> = ({ onClose, refetchFunc }) => {
  const { sendMessage } = useWebsocket();

  const {
    data: virtualGoodShortCodes,
    error: virtualGoodShortCodesError,
    refetch: virtualGoodShortCodesRefetch,
    isLoading: isLoadingVirtualGoodShortCodes,
    isFetching: isFetchingVirtualGoodShortCodes,
  } = useGetListQuery(QueryListName.virtualGoodShortCodes);

  const {
    data: virtualGoodTags,
    error: virtualGoodTagsError,
    refetch: virtualGoodTagsRefetch,
    isLoading: isLoadingVirtualGoodTags,
    isFetching: isFetchingVirtualGoodTags,
  } = useGetListQuery(QueryListName.virtualGoodTags);

  const {
    data: characters,
    error: charactersError,
    refetch: charactersRefetch,
    isLoading: isLoadingCharacters,
    isFetching: isFetchingCharacters,
  } = useGetListQuery(QueryListName.character);

  if (
    isLoadingVirtualGoodShortCodes ||
    isLoadingVirtualGoodTags ||
    isLoadingCharacters
  ) {
    return <PaperPageSpinner />;
  }

  if (virtualGoodShortCodesError) {
    return (
      <PaperPageError
        refetch={virtualGoodShortCodesRefetch}
        disabled={isFetchingVirtualGoodShortCodes}
        message="refetch"
      />
    );
  }

  if (virtualGoodTagsError) {
    return (
      <PaperPageError
        refetch={virtualGoodTagsRefetch}
        disabled={isFetchingVirtualGoodTags}
        message="refetch"
      />
    );
  }

  if (charactersError) {
    return (
      <PaperPageError
        refetch={charactersRefetch}
        disabled={isFetchingCharacters}
        message="refetch"
      />
    );
  }

  const onCreate = (data: FormValues) => {
    console.log("test1111", data);
    const eventObject = CreateObject(data);
    const processedMsg = {
      action: WebsocketActionType.createEventType,
      inputParams: JSON.stringify(eventObject),
      id: eventObject.eventID,
    };
    sendMessage(processedMsg);
    onClose?.();
  };

  return (
    <CustomContainer maxWidth="xl">
      <EventForms
        charactersList={characters}
        vGoodTags={virtualGoodTags}
        vGoodShortCodes={virtualGoodShortCodes}
        onCreate={onCreate}
        refetchFunc={refetchFunc}
      />
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)({
  height: "100%",
  backgroundColor: "white",
});

export default Events;
