import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";
import { PaperPageSpinner } from "../PaperPage";
import DynamicForm from "../DynamicForm/DynamicForm";

import {
  useEditPlayerPersonalValleyMutation,
  useEditPlayerMutation,
  useEditPetPlayerMutation,
  useGetPlayerPersonalValleysQuery,
  useGetPetPlayersQuery,
  useGetPlayersQuery,
  useGetPlayerMoneySpentQuery,
  useEditPlayerMoneySpentMutation,
} from "../../slices/players/api";
import { useSubmitFormErrorHandler } from "../../utils/formHelpers";
import {
  useEditVirtualGoodMutation,
  useGetVirtualGoodsQuery,
} from "../../slices/virtualGoods/api";
import {
  useEditGroupMutation,
  useGetGroupManagementQuery,
} from "../../slices/groupManagement/api";
import {
  useEditQuestMutation,
  useGetQuestsQuery,
} from "../../slices/quests/api";

import {
  useEditChallengeEventsMutation,
  useGetChallengeEventsQuery,
} from "../../slices/challengeEvents/api";

import { useGetFeedbackTableDataQuery } from "../../slices/feedback/api";
import { handledSubmitData } from "../DynamicForm/types";
import { COLLECTION_NAMES, DB_NAMES } from "../../consts";
import { PlayerEntry } from "../../slices/players/types";
import { VirtualGoodsType } from "../../slices/virtualGoods/types";
import { QuestsType } from "../../slices/quests/types";
import { ChallengeEventsType } from "../../slices/challengeEvents/types";

type EditProps = {
  id: string | null;
  dbName: typeof DB_NAMES[keyof typeof DB_NAMES];
  collectionName: typeof COLLECTION_NAMES[keyof typeof COLLECTION_NAMES];
  title?: string | ReactNode;
  getEditItemTools:
    | typeof useEditPlayerMutation
    | typeof useEditVirtualGoodMutation
    | typeof useEditQuestMutation
    | typeof useEditChallengeEventsMutation
    | typeof useEditGroupMutation
    | typeof useEditPlayerPersonalValleyMutation
    | typeof useEditPetPlayerMutation
    | typeof useEditPlayerMoneySpentMutation;
  getQueryData:
    | typeof useGetPlayersQuery
    | typeof useGetVirtualGoodsQuery
    | typeof useGetQuestsQuery
    | typeof useGetChallengeEventsQuery
    | typeof useGetFeedbackTableDataQuery
    | typeof useGetGroupManagementQuery
    | typeof useGetPlayerPersonalValleysQuery
    | typeof useGetPetPlayersQuery
    | typeof useGetPlayerMoneySpentQuery;
  errorsDescriptionPath: string;
  vGoodsNames?: string[];
  isVGoodsNamesLoading?: boolean;
  setIsEditMode?: Dispatch<SetStateAction<boolean>>;
  setPlayerName?: Dispatch<SetStateAction<string>>;
  isEditBtnHidden?: boolean;
};

export const Edit = memo(
  ({
    id,
    collectionName,
    dbName,
    title = "",
    getEditItemTools,
    getQueryData,
    errorsDescriptionPath,
    vGoodsNames,
    isVGoodsNamesLoading,
    setIsEditMode,
    setPlayerName,
    isEditBtnHidden = false,
  }: EditProps) => {
    const { t } = useTranslation();

    const { data, isFetching: isDataFetching } = getQueryData({
      search: { _id: id },
      limit: 1000,
      page: 1,
      dbName,
      collectionName,
    });

    const [editItem, { isLoading: isEditLoading }] = getEditItemTools();
    const { catchFormError, fieldsErrors } = useSubmitFormErrorHandler(
      t(errorsDescriptionPath)
    );
    const [isEditable, setIsEditable] = useState(false);

    const setEditMode = useCallback(
      (isEditMode) => {
        setIsEditable(isEditMode);
        setIsEditMode && setIsEditMode(isEditMode);
      },
      [setIsEditMode]
    );

    const handleSubmitForm = useCallback(
      (data: handledSubmitData) => {
        const needToUpdateDataKeys = Object.keys(data.needToUpdate);
        const needToIncrementDataKeys = Object.keys(data.needToIncrement);

        const editField = (keys: string[], isIncremented: boolean) => {
          const newParams = keys.map((inputName) => {
            const value = isIncremented
              ? data.needToIncrement[inputName]
              : data.needToUpdate[inputName];
            return {
              path: inputName.replaceAll("/", "."),
              value,
            };
          });

          editItem({
            dbName,
            collectionName,
            id,
            newParams,
            isIncremented,
          })
            .unwrap()
            .catch(catchFormError);
        };

        if (needToUpdateDataKeys.length) {
          editField(needToUpdateDataKeys, false);
        }

        if (needToIncrementDataKeys.length) {
          editField(needToIncrementDataKeys, true);
        }
      },
      [catchFormError, collectionName, dbName, editItem, id]
    );
    const formattedUserData:
      | PlayerEntry
      | QuestsType
      | ChallengeEventsType
      | any = data?.docs[0];

    useEffect(() => {
      const data = formattedUserData as PlayerEntry;
      if (data?.displayName && setPlayerName) {
        setPlayerName(data?.displayName);
      }
    }, [formattedUserData, setPlayerName]);

    const editBody = useMemo(() => {
      if (isDataFetching || isEditLoading || isVGoodsNamesLoading) {
        return <PaperPageSpinner />;
      }

      if (!formattedUserData) {
        return (
          <Typography
            variant="body1"
            color="primary"
            sx={{ fontWeight: "bold", ml: 2.5, color: "primary.light" }}
          >
            {t("errors.noData")}
          </Typography>
        );
      }

      return (
        <DynamicForm
          collectionName={collectionName}
          data={formattedUserData}
          handleSubmitForm={handleSubmitForm}
          fieldsErrors={fieldsErrors}
          vGoodsNames={vGoodsNames}
          isEditable={isEditable}
          setIsEditable={setEditMode}
          id={id}
          isEditBtnHidden={isEditBtnHidden}
        />
      );
    }, [
      collectionName,
      fieldsErrors,
      formattedUserData,
      handleSubmitForm,
      id,
      isDataFetching,
      isEditBtnHidden,
      isEditLoading,
      isEditable,
      isVGoodsNamesLoading,
      setEditMode,
      t,
      vGoodsNames,
    ]);

    return (
      <Stack sx={{ minHeight: 400, backgroundColor: "white" }}>
        <h3 style={{ marginLeft: "20px", paddingTop: "20px", marginTop: 0 }}>
          {title}
        </h3>

        {editBody}
      </Stack>
    );
  }
);
