import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { Controller, FieldValues, useForm } from "react-hook-form";
import i18n from "../../i18n";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Button, Container, styled, TextField } from "@mui/material";
import { PaperPageSpinner } from "../../components/PaperPage";
import { SpinnerButton } from "../../components/SpinnerButton";

import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";

import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import useWebsocket from "../../utils/useWebsocket";
import { useSelector } from "react-redux";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import dayjs from "dayjs";

enum ActionStatuses {
  none = "none",
  scheduledEnabling = "scheduledEnabling",
  scheduledDisabling = "scheduledDisabling",
}

const maintenanceSchema = yup.object({
  description: yup.string().required(i18n.t("validationErrors.required")),
  requiredIOSVersion: yup.string().notRequired(),
  requiredAndroidVersion: yup.string().notRequired(),
  delay: yup.number().required(i18n.t("validationErrors.required")),
  estimatedDuration: yup.number().required(i18n.t("validationErrors.required")),
});

const getMaintenanceStatusParams = {
  action: WebsocketActionType.getMaintenanceStatus,
  inputParams: "{}",
};

const Maintenance = () => {
  const { t } = useTranslation();
  const title = useTitle(t("maintenance.title"));
  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);

  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [statusInfo, setStatusInfo] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(maintenanceSchema),
  });

  const handleSubmittedData = useCallback(
    ({
      description,
      delay,
      estimatedDuration,
      requiredAndroidVersion,
      requiredIOSVersion,
    }: FieldValues) => {
      setIsSending(true);

      const processedMsg = {
        action: WebsocketActionType.changeMaintenanceStatus,
        inputParams: JSON.stringify({
          description,
          requiredAndroidVersion,
          requiredIOSVersion,
          minutesDelay: delay,
          estimatedDuration,
          activate: !isActive,
        }),
      };

      sendMessage(processedMsg);
    },
    [isActive, sendMessage]
  );

  const handleClickCancel = useCallback(() => {
    setIsSending(true);

    const processedMsg = {
      action: WebsocketActionType.cancelSchedullerMaintenanceStatus,
      inputParams: JSON.stringify({}),
    };

    sendMessage(processedMsg);
  }, [sendMessage]);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);
      console.log("msg", msg);

      switch (msg?.action) {
        case WebsocketActionType.maintenanceStatusChanged: {
          let text = null;
          const date =
            msg?.data?.actionTimestamp &&
            dayjs
              .unix(msg?.data?.actionTimestamp)
              .format("YYYY/MM/DD, HH:mm:ss");
          switch (msg?.data?.actionStatus) {
            case ActionStatuses.none: {
              setIsScheduled(false);
              text = null;
              break;
            }
            case ActionStatuses.scheduledEnabling: {
              setIsScheduled(true);
              text = `maintenance status will be activated at ${date}`;
              break;
            }
            case ActionStatuses.scheduledDisabling: {
              setIsScheduled(true);
              text = `maintenance status will be deactivated at ${date}`;
              break;
            }
          }
          setStatusInfo(text);
          setIsActive(msg?.data?.active);
          break;
        }
        case WebsocketActionType.changeMaintenanceStatus: {
          setIsSending(false);

          if (msg?.data?.error) {
            feedError(msg?.data?.error);
          }

          if (msg?.data?.scriptData?.message) {
            reset();
            feedSuccess(msg?.data?.scriptData?.message);
          }
          break;
        }
        case WebsocketActionType.cancelSchedullerMaintenanceStatus: {
          setIsSending(false);

          if (msg.status === WebsocketMessageStatus.success) {
            feedSuccess(
              t("maintenance.cancelScheduledMaintenanceStatusMessageSuccess")
            );
          }
          if (msg.status === WebsocketMessageStatus.error) {
            feedError(
              t("maintenance.cancelScheduledMaintenanceStatusMessageError")
            );
          }
          break;
        }
        case WebsocketActionType.getMaintenanceStatus: {
          let text = null;
          const date =
            msg?.data?.maintenanceModeRecord?.actionTimestamp &&
            dayjs
              .unix(msg?.data?.maintenanceModeRecord?.actionTimestamp)
              .format("YYYY/MM/DD, HH:mm:ss");
          switch (msg?.data?.maintenanceModeRecord?.actionStatus) {
            case ActionStatuses.none: {
              setIsScheduled(false);
              text = null;
              break;
            }
            case ActionStatuses.scheduledEnabling: {
              setIsScheduled(true);
              text = `maintenance status will be activated at ${date}`;
              break;
            }
            case ActionStatuses.scheduledDisabling: {
              setIsScheduled(true);
              text = `maintenance status will be deactivated at ${date}`;
              break;
            }
          }
          setStatusInfo(text);
          setIsActive(msg?.data?.maintenanceModeRecord?.active);
          break;
        }
      }
    },
    [feedError, feedSuccess, reset, t]
  );

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  useEffect(() => {
    if (wasWebsocketOpen && isActive === null) {
      sendMessage(getMaintenanceStatusParams);
    }
  }, [isActive, sendMessage, wasWebsocketOpen]);

  if (isActive === null) {
    return <PaperPageSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomContainer maxWidth="xl">
        <form
          onSubmit={handleSubmit(handleSubmittedData)}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "400px",
          }}
        >
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <TextField
                  autoComplete="on"
                  sx={{ mb: 1 }}
                  variant="outlined"
                  size="small"
                  label={t("description")}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isScheduled}
                />
              );
            }}
          />
          <Controller
            name="estimatedDuration"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <TextField
                  autoComplete="on"
                  sx={{ mb: 1 }}
                  variant="outlined"
                  size="small"
                  type="number"
                  label={t("estimated duration in minutes")}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isScheduled}
                />
              );
            }}
          />
          <Controller
            name="delay"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <TextField
                  autoComplete="on"
                  sx={{ mb: 1 }}
                  variant="outlined"
                  size="small"
                  type="number"
                  label={t("delay in minutes")}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isScheduled}
                />
              );
            }}
          />
          <Controller
            name="requiredAndroidVersion"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <TextField
                  autoComplete="on"
                  sx={{ mb: 1 }}
                  variant="outlined"
                  size="small"
                  label={t("requiredAndroidVersion")}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isScheduled}
                />
              );
            }}
          />
          <Controller
            name="requiredIOSVersion"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <TextField
                  autoComplete="on"
                  sx={{ mb: 1 }}
                  variant="outlined"
                  size="small"
                  label={t("requiredIOSVersion")}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  disabled={isScheduled}
                />
              );
            }}
          />
          <Box display="flex" marginTop="8px">
            <SpinnerButton
              loading={isSending}
              buttonName={t(
                isActive ? "buttonNames.deactivate" : "buttonNames.activate"
              )}
            />
            {isScheduled && (
              <Button
                sx={{ ml: 1 }}
                variant="outlined"
                size="medium"
                disabled={isSending}
                onClick={handleClickCancel}
              >
                {t("buttonNames.cancel")}
              </Button>
            )}
          </Box>
        </form>
        <h3 style={{ color: "gray" }}>{statusInfo}</h3>
      </CustomContainer>
    </>
  );
};

const CustomContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "40px",
});

export default Maintenance;
