import * as yup from "yup";
import { COLLECTION_NAMES } from "../../../consts";
import { DynamicSchemaListType } from "../types";
import { playerSchema } from "./playerSchema";
import { virtualGoodsSchema } from "./virtualGoodsSchema";
import { groupManagementSchema } from "./groupManagementSchema";
import { questsSchema } from "./questsSchema";
import { challengeEventsSchema } from "./challengeEventsSchema";
import { petPlayerSchema } from "./petPlayerSchema";
import { playerPersonalValleySchema } from "./playerPersonalValleySchema";
import { playerMoneySpentSchema } from "./playerMoneySpentSchema";

const {
  PLAYER,
  VIRTUAL_GOODS,
  GROUP_MANAGEMENT,
  QUESTS_DATA,
  EVENT_DATA,
  PET_PLAYER,
  PLAYER_PERSONAL_VALLEY,
  PLAYER_MONEY_SPENT,
} = COLLECTION_NAMES;

export const dynamicSchemaList: DynamicSchemaListType = {
  [PLAYER]: playerSchema,
  [VIRTUAL_GOODS]: virtualGoodsSchema,
  [GROUP_MANAGEMENT]: groupManagementSchema,
  [QUESTS_DATA]: questsSchema,
  [EVENT_DATA]: challengeEventsSchema,
  [PET_PLAYER]: petPlayerSchema,
  [PLAYER_PERSONAL_VALLEY]: playerPersonalValleySchema,
  [PLAYER_MONEY_SPENT]: playerMoneySpentSchema,
} as const;

export const numericYupRule = yup.number().integer().min(0);

export const numericFieldNames: string[] = [
  "min",
  "max",
  "currentAmount",
  "amount",
  "_randomItemTierObject_typeID",
  "rarities",
];
