import { QueryListName } from "../../../slices/lists/types";
import { ArrayItemType, InputType } from "../consts";
import { DynamicSchema } from "../types";

export const challengeEventsSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      eventID: {
        type: InputType.text,
        label: "eventID",
        placeholder: "Edit eventID",
        additionalFields: [],
        children: {},
      },
      leaderBoard: {
        type: InputType.text,
        label: "leaderBoard",
        placeholder: "Edit leaderBoard",
        additionalFields: [],
        children: {},
      },
      votingTime: {
        type: InputType.numeric,
        label: "votingTime",
        placeholder: "Edit votingTime",
        additionalFields: [],
        children: {},
      },
      eventName: {
        type: InputType.text,
        label: "eventName",
        placeholder: "Edit eventName",
        additionalFields: [],
        children: {},
      },
      eventDesc: {
        type: InputType.text,
        label: "eventDesc",
        placeholder: "Edit eventDesc",
        additionalFields: [],
        children: {},
      },
      eventPic: {
        type: InputType.text,
        label: "eventPic",
        placeholder: "Edit eventPic",
        additionalFields: [],
        children: {},
      },
      eventSeason: {
        type: InputType.text,
        label: "eventSeason",
        placeholder: "Edit eventSeason",
        additionalFields: [],
        children: {},
      },
      character: {
        type: InputType.text,
        label: "character",
        placeholder: "Edit character",
        additionalFields: [],
        children: {},
      },
      startTime: {
        type: InputType.numeric,
        label: "startTime",
        placeholder: "Edit startTime",
        additionalFields: [],
        children: {},
      },
      dueTime: {
        type: InputType.numeric,
        label: "dueTime",
        placeholder: "Edit dueTime",
        additionalFields: [],
        children: {},
      },
      active: {
        type: InputType.checkbox,
        label: "active",
        placeholder: "Edit active",
        additionalFields: [],
        children: {},
      },
      visible: {
        type: InputType.checkbox,
        label: "visible",
        placeholder: "Edit visible",
        additionalFields: [],
        children: {},
      },
      playerCount: {
        type: InputType.numeric,
        label: "playerCount",
        placeholder: "Edit playerCount",
        additionalFields: [],
        children: {},
      },
      totalCollected: {
        type: InputType.numeric,
        label: "totalCollected",
        placeholder: "Edit totalCollected",
        additionalFields: [],
        children: {},
      },
      averageCollected: {
        type: InputType.numeric,
        label: "averageCollected",
        placeholder: "Edit averageCollected",
        additionalFields: [],
        children: {},
      },
    },
  },
};
