import React from "react";
import dayjs from "dayjs";
import OpenEditBtn from "../../components/OpenEditBtn";
import { ColumnData } from "../../components/CustomDataGrid/types";
import { ChallengeEventsType } from "../../slices/challengeEvents/types";
import { searchFieldsBackType } from "../../components/DataTable/DataTableTypes";
import { ROUTE_PATHS } from "../../consts";

export const challengeEventsSearchFieldsBack: searchFieldsBackType[] = [
  {
    key: "eventID",
    type: "text",
    label: "Event ID",
  },
  {
    key: "eventName",
    type: "text",
    label: "Event Name",
  },
];

export const initialCustomDisabled = {
  tags: false,
  character: false,
  questRarity: false,
};

export const challengeEventsTableColumns = (
  handleOpenEditBtnClick: any
): ColumnData<ChallengeEventsType>[] => {
  return [
    {
      width: 70,
      label: "N",
      dataKey: "id",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: (rowData, rowIndex: number) => rowIndex + 1,
    },
    {
      width: 130,
      label: "Event ID",
      dataKey: "eventID",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ eventID }) => <span title={eventID}>{eventID}</span>,
    },
    {
      width: 120,
      label: "EventName",
      dataKey: "eventName",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ eventName }) => eventName,
    },
    {
      width: 90,
      label: "EventSeason",
      dataKey: "eventSeason",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ eventSeason }) => eventSeason,
    },
    {
      width: 120,
      label: "Character",
      dataKey: "character",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ character }) => character,
    },
    {
      width: 180,
      label: "StartTime",
      dataKey: "startTime",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ startTime }) =>
        startTime ? dayjs.unix(startTime).format("YYYY/MM/DD, HH:mm:ss") : "",
    },
    {
      width: 180,
      label: "DueTime",
      dataKey: "dueTime",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ dueTime }) =>
        dueTime ? dayjs.unix(dueTime).format("YYYY/MM/DD, HH:mm:ss") : "",
    },
    {
      width: 120,
      label: "Tag",
      dataKey: "tag",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ tag }) => tag,
    },
    {
      width: 120,
      label: "EventType",
      dataKey: "eventType",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ eventType }) => eventType,
    },
    {
      width: 120,
      label: "LeaderBoard",
      dataKey: "leaderBoard",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ leaderBoard }) => leaderBoard,
    },
    {
      width: 120,
      label: "Active",
      dataKey: "active",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ active }) => (active ? "Yes" : "No"),
    },
    {
      width: 60,
      label: "Visible",
      dataKey: "visible",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ visible }) => (visible ? "Yes" : "No"),
    },
    {
      width: 60,
      label: "Action",
      dataKey: "action",
      // isDisabled: ({ disabled }) => disabled,
      renderCell: ({ _id }) => (
        <OpenEditBtn
          id={_id}
          routePath={ROUTE_PATHS.CHALLENGE_EVENTS}
          handleOpenEditBtnClick={handleOpenEditBtnClick}
        />
      ),
    },
  ];
};
