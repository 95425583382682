import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Box, Checkbox, MenuItem } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import { FormValues, EventFormsTypes } from "./types";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import {
  CustomBox,
  CustomTypography,
  CustomSelect,
  CustomSelectActive,
  CustomErrorBox,
} from "./EventFormsStyles";

import { Tags } from "./Tags";
import { Rewards } from "./Rewards";

export const EventForms = ({
  charactersList,
  vGoodTags,
  vGoodShortCodes,
  refetchFunc,
  ...props
}: EventFormsTypes) => {
  const formRef = useRef(null);
  const { control, handleSubmit, watch, reset, resetField } =
    useForm<FormValues>({
      defaultValues: {
        eventID: "",
        eventName: "",
        eventDesc: "",
        eventPic: "",
        eventType: "items",
        character: "",
        directCollectibles: false,
        item1: "-",
        item2: "-",
        item3: "-",
        item4: "-",
        item5: "-",
        startTime: null,
        dueTime: null,
        votingTime: null,
        // ...add other fields with their default values
      },
    });

  const onSubmit = (data: FormValues) => {
    props.onCreate(data);
    reset({
      eventID: "",
      eventName: "",
      eventDesc: "",
      eventPic: "",
      eventType: "items",
      character: "",
      directCollectibles: false,
      item1: "-",
      item2: "-",
      item3: "-",
      item4: "-",
      item5: "-",
      startTime: null,
      dueTime: null,
      votingTime: null,
    });
    if (refetchFunc) {
      refetchFunc();
    }
  };

  const watchEventType = watch("eventType", "items");
  const watchActiveDirectCollectibles = watch("directCollectibles", false);
  const watchAddCollectibles = watch("directCollectibles", false);

  useEffect(() => {
    resetField("col1");
  }, [resetField, watch, watchActiveDirectCollectibles]);
  console.log("watchActiveDirectCollectibles1");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box style={{ backgroundColor: "white" }}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <h3>New Event Type</h3>
          <h4>
            Please make a Applanga key for Name, which can be shown in the
            reward screen. Same as event name, but &apos;_1line&apos; added in
            the end!
          </h4>
          <h3>Event Details</h3>
          <CustomBox>
            <CustomTypography>Event ID (backend)</CustomTypography>
            <Controller
              control={control}
              name="eventID"
              rules={{
                required: "required",
              }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <TextField
                      size={"small"}
                      style={{ width: 400 }}
                      variant="outlined"
                      error={invalid}
                      {...field}
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Event Name (Applanga)</CustomTypography>
            <Controller
              control={control}
              name="eventName"
              rules={{
                required: "required",
              }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <TextField
                      size={"small"}
                      style={{ width: 400 }}
                      variant="outlined"
                      error={invalid}
                      {...field}
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Event description (Applanga)</CustomTypography>
            <Controller
              control={control}
              name="eventDesc"
              rules={{
                required: "required",
              }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <TextField
                      size={"small"}
                      style={{ width: 400 }}
                      variant="outlined"
                      error={invalid}
                      {...field}
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Event Picture (shortcode)</CustomTypography>
            <Controller
              control={control}
              name="eventPic"
              rules={{
                required: "required",
              }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <TextField
                      size={"small"}
                      style={{ width: 400 }}
                      variant="outlined"
                      error={invalid}
                      {...field}
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Event Type</CustomTypography>
            <Controller
              control={control}
              name="eventType"
              defaultValue={"items"}
              render={({ field }) => {
                return (
                  <CustomSelect {...field}>
                    <MenuItem key={"items"} value={"items"}>
                      items
                    </MenuItem>
                    <MenuItem key={"walk"} value={"walk"}>
                      walk
                    </MenuItem>
                    <MenuItem key={"themevalley"} value={"themevalley"}>
                      themevalley
                    </MenuItem>
                  </CustomSelect>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Character</CustomTypography>
            <Controller
              control={control}
              name="character"
              rules={{
                required: "required",
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <CustomSelect {...field}>
                      {charactersList.map((character: string) => (
                        <MenuItem key={character} value={character}>
                          {character}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Start Time (UTC)</CustomTypography>
            <Controller
              control={control}
              name="startTime"
              rules={{
                required: "required",
              }}
              defaultValue={null}
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          size={"small"}
                          style={{ width: 400 }}
                          variant="outlined"
                          error={invalid}
                        />
                      )}
                      {...field}
                      ampm={false}
                      inputFormat="yyyy/MM/dd HH:mm"
                      mask="____/__/__ __:__"
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          <CustomBox>
            <CustomTypography>Due Time (UTC)</CustomTypography>
            <Controller
              control={control}
              name="dueTime"
              rules={{
                required: "required",
              }}
              defaultValue={null}
              render={({ field, fieldState: { invalid, error } }) => {
                return (
                  <>
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          size={"small"}
                          style={{ width: 400 }}
                          variant="outlined"
                          error={invalid}
                        />
                      )}
                      {...field}
                      ampm={false}
                      inputFormat="yyyy/MM/dd HH:mm"
                      mask="____/__/__ __:__"
                    />
                    {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                  </>
                );
              }}
            />
          </CustomBox>
          {watchEventType === "themevalley" && (
            <CustomBox>
              <CustomTypography>Voting Time (UTC)</CustomTypography>
              <Controller
                control={control}
                name="votingTime"
                rules={{
                  required: "required",
                }}
                defaultValue={null}
                render={({ field, fieldState: { invalid, error } }) => {
                  return (
                    <>
                      <DateTimePicker
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            size={"small"}
                            style={{ width: 400 }}
                            variant="outlined"
                            error={invalid}
                          />
                        )}
                        {...field}
                        ampm={false}
                        inputFormat="yyyy/MM/dd HH:mm"
                        mask="____/__/__ __:__"
                      />
                      {error && (
                        <CustomErrorBox>{error.message}</CustomErrorBox>
                      )}
                    </>
                  );
                }}
              />
            </CustomBox>
          )}
          {watchEventType === "items" && (
            <>
              {/* <div>
                <h4>Select collectibles directly</h4>
              </div> */}
              <CustomBox>
                <h4>Add collectibles</h4>
                <Controller
                  name="directCollectibles"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox {...field} defaultChecked={false} />
                  )}
                />
              </CustomBox>
              {vGoodShortCodes?.length > 0 && (
                <div style={{ display: "flex" }}>
                  <Controller
                    control={control}
                    name="item1"
                    defaultValue="-"
                    render={({ field }) => {
                      return (
                        <CustomSelectActive
                          {...field}
                          disabled={!watchAddCollectibles}
                        >
                          <MenuItem key="-" value="-">
                            -
                          </MenuItem>
                          {vGoodShortCodes.map((shortCode: string) => (
                            <MenuItem key={shortCode} value={shortCode}>
                              {shortCode}
                            </MenuItem>
                          ))}
                        </CustomSelectActive>
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="item2"
                    defaultValue="-"
                    render={({ field }) => {
                      return (
                        <CustomSelectActive
                          {...field}
                          disabled={!watchAddCollectibles}
                        >
                          <MenuItem key="-" value="-">
                            -
                          </MenuItem>
                          {vGoodShortCodes.map((shortCode: string) => (
                            <MenuItem key={shortCode} value={shortCode}>
                              {shortCode}
                            </MenuItem>
                          ))}
                        </CustomSelectActive>
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="item3"
                    defaultValue="-"
                    render={({ field }) => {
                      return (
                        <CustomSelectActive
                          {...field}
                          disabled={!watchAddCollectibles}
                        >
                          <MenuItem key="-" value="-">
                            -
                          </MenuItem>
                          {vGoodShortCodes.map((shortCode: string) => (
                            <MenuItem key={shortCode} value={shortCode}>
                              {shortCode}
                            </MenuItem>
                          ))}
                        </CustomSelectActive>
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="item4"
                    defaultValue="-"
                    render={({ field }) => {
                      return (
                        <CustomSelectActive
                          {...field}
                          disabled={!watchAddCollectibles}
                        >
                          <MenuItem key="-" value="-">
                            -
                          </MenuItem>
                          {vGoodShortCodes.map((shortCode: string) => (
                            <MenuItem key={shortCode} value={shortCode}>
                              {shortCode}
                            </MenuItem>
                          ))}
                        </CustomSelectActive>
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="item5"
                    defaultValue="-"
                    render={({ field }) => {
                      return (
                        <CustomSelectActive
                          {...field}
                          disabled={!watchAddCollectibles}
                        >
                          <MenuItem key="-" value="-">
                            -
                          </MenuItem>
                          {vGoodShortCodes.map((shortCode: string) => (
                            <MenuItem key={shortCode} value={shortCode}>
                              {shortCode}
                            </MenuItem>
                          ))}
                        </CustomSelectActive>
                      );
                    }}
                  />
                </div>
              )}
              <br />
              <br />
              <CustomTypography> Tag and rarity based items</CustomTypography>
              <br />
              {vGoodTags?.length > 0 && (
                <Tags
                  control={control}
                  vGoodTags={vGoodTags}
                  watchActiveDirectCollectibles={watchActiveDirectCollectibles}
                />
              )}
            </>
          )}
          {vGoodShortCodes?.length > 1 && <Rewards control={control} />}
          <br />
          <Button
            type="submit"
            variant="contained"
            style={{ marginLeft: "8px" }}
          >
            Create
          </Button>
        </form>
        <br />
        <br />
      </Box>
    </LocalizationProvider>
  );
};
