export const LS_KEY_DRAWER_OPEN = "drawerOpen" as const;

export const FETCH_ERROR = "FETCH_ERROR" as const;
export const ERROR_STATUS_UNAUTHORIZED = 401 as const;

export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const CURRENT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const ENVIRONMENT_OPTION = {
  dev: "dev",
  demo: "demo",
  production: "production",
} as const;

export const DB_NAMES = {
  SYSTEM: "system",
  META: "meta",
  RUNTIME: "runtime",
} as const;

export const COLLECTION_NAMES = {
  PLAYER: "player",
  VIRTUAL_GOODS: "virtualGoods",
  MOOMIN_CHARACTERS: "moominCharacters",
  GROUP_MANAGEMENT: "teamData",
  TEAM_CHAT_HISTORY: "teamChatHistory",
  TEAM_DATA: "teamData",
  QUESTS_DATA: "questsData",
  EVENT_DATA: "eventData",
  FEEDBACK_DATA: "feedbackData",
  PROBABILITY_PRESET: "probabilityPreset",
  PROBABILITY_DEFAULT_CONFIG: "probabilityDefaultConfig",
  DISTRIBUTION_SYSTEM: "distributionSystem",
  PLAYER_PERSONAL_VALLEY: "playerPersonalValley",
  PET_PLAYER: "petPlayer",
  PLAYER_MONEY_SPENT: "playerMoneySpent",
} as const;

export const ROUTE_PATHS = {
  SEARCH: "/search",
  UPDATE: "/update",
  DELETE: "/delete",
  DISTINCT: "/distinct",
  ALL: "/all",
  GET: "/get",
  COUNT: "/count",
  CREATE: "/create",
  CREATE_UPDATE: "/createUpdate",
  ADD: "/add",
  SET: "/set",
  SIGN_IN: "/signin",
  PLAYERS: "/players",
  V_GOODS: "/virtualGoods",
  QUESTS: "/quests",
  CHALLENGE_EVENTS: "/challengeEvents",
  STORE_CONTENT: "/storeContent",
  STORE_CONTENT_API: "/storeTabConfiguration",
  DEPLOYMENT: "/deployment",
  FEEDBACK: "/feedback",
  MAINTENANCE: "/maintenance",
  SUPPORT: "/support",
  GROUP_MANAGEMENT: "/groupManagement",
  DISTRIBUTION_SYSTEM: "/distributionSystem",
  DELETE_PLAYERS: "/deletePlayers",
  MAP_CONTENT: "/mapContent",
  SERVICE_STATUS: "/serviceStatus",
  ANALYTICS: "/analytics",
  LISTS: "/lists",
  NEW_PLAYERS_KPI: "/newPlayersKpi",
  DAILY_ACTIVE_PLAYERS_KPI: "/dailyActivePlayersKpi",
  MONTHLY_ACTIVE_PLAYERS_KPI: "/monthlyActivePlayersKpi",
  STEPS_KPI: "/stepsKpi",
  WALK_DISTANCE_KPI: "/walkDistanceKPI",
  PROBABILITY_CONFIG: "/probabilityConfig",
} as const;

export const AUTH_CHALLENGE_NAMES = {
  MFA_SETUP: "MFA_SETUP",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
} as const;

export enum ROLE {
  Admin = "Admin",
  PlayerSupport = "PlayerSupport",
  PlayerSupportAdvanced = "PlayerSupportAdvanced",
  GameContentDeveloper = "GameContentDeveloper",
  ViewAnalyticsData = "ViewAnalyticsData",
}

export enum CHAT_STATUSES {
  open = "open",
  closed = "closed",
  waitingResponse = "waiting response",
  waitingTech = "waiting tech",
  handledInAsana = "handled in asana",
}

export enum CREATE_ITEM_TYPE {
  quest = "quest",
  virtualGood = "virtualGood",
  probabilityConfigPreset = "probabilityConfigPreset",
}

export const MSG_TIMEOUT = 5 as const;
export const IN_GAME_MSG_TIMEOUT = 30 as const;
export const FEED_TIMEOUT = 6000 as const;
export const MSG_PAGE_STEP = 50 as const;
export const ROWS_PER_PAGE_LIST = [10, 100, 500, 1000, 5000] as const;
export const DEFAULT_PAGE_SIZE = 100 as const;
export const DATE_MASK_DEFAULT = "YYYY/MM/DD HH:mm";
export const DATE_MASK_WITH_SECONDS = `${DATE_MASK_DEFAULT}:ss`;

export const COGNITO_LOCAL_STORAGE_IDENTIFIER =
  `CognitoIdentityServiceProvider.${process.env.REACT_APP_APP_CLIENT_ID}` as const;
